<template>
  <div>
    <ClientOnly>
      <NuxtLoadingIndicator :duration="5000" color="repeating-linear-gradient(to right, #A77C37 0%, #CAB087 100%)" />
    </ClientOnly>
    <OrganismsGlobalTopBar v-if="topBarData" :is-enabled="!!topBarData" :text-color="topBarData.textColor" :background-color="topBarData.backgroundColor" :html="topBarData.text" :campaign="topBarData.campaign" ref="topBar"></OrganismsGlobalTopBar>
    <OrganismsGlobalAppHeader />
      <div>
        <slot></slot>
      </div>
      <OrganismsGlobalAppFooter></OrganismsGlobalAppFooter>
      <LazyMoleculesGlobalVideoPlayer />
  </div>
</template>
<script setup>

const topBarData = useState('topBarData', () => null);

</script>